import React from "react";
import emailConfirmationImage from "../../assets/layout/emailConfirmation.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Heading, Stack, HStack, Text, Button, Image } from "@chakra-ui/react";

const EmailConfirmation = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  //const { id, color } = state; // Read values passed on state
  console.log(JSON.stringify(state));

  const navigateToStoreDetailSingUp = () => {
    navigate("/inquiry", {
      state: {
        email: `${state.email}`
      }
    });
  };

  return (
    <>
      <Container maxW="7xl" p={4}>
        <Stack direction="column" spacing={6} alignItems="center" mt={8} mb={8}>
          <Image src={emailConfirmationImage} alt="company logo" w="300px" />
          <Heading as="h1" fontSize="xl" fontWeight="bold" textAlign="center" maxW="800px">
            Thank you, Your request has been received, the hotspot team will verify your request, if
            your request is approved, you will soon receive an invite to your email address
          </Heading>
          <Text maxW="500px" fontSize="lg" textAlign="center" color="gray.500">
            {state.email}
          </Text>
          <HStack spacing={5}>
            <Button
              colorScheme="orange"
              variant="solid"
              rounded="md"
              size="lg"
              onClick={navigateToStoreDetailSingUp}>
              Close
            </Button>
          </HStack>
        </Stack>
        <Stack spacing={5} alignItems="center" mb={8}>
          <HStack
            spacing={{ base: 0, md: 10 }}
            justifyContent="center"
            maxW={{ base: "500px", md: "100%" }}
            flexWrap="wrap"></HStack>
          <Text maxW="500px" fontSize="md" textAlign="center" color="gray.500"></Text>
        </Stack>
      </Container>
    </>
  );
};

export default EmailConfirmation;
