import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  FormHelperText
} from "@chakra-ui/react";
import MerchantService from "../../services/api/merchantService";
import { useAppContext } from "../../hooks/AppContext";
import { useNavigate } from "react-router-dom";
import CheckMd5 from "./../../shared/utils/md5Checker";
export default function EditProfile() {
  const { toaster } = useAppContext();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please Enter Valid Name")
      .max(40)
      .required("Please Enter the First Name"),
    last_name: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please Enter Valid Name")
      .max(40)
      .required("Please Enter the Last Name"),
    email: Yup.string().email().required("Email is not Valid"),
    password: Yup.string()
      .required("No Password Provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    phone: Yup.string()
      .required("Please Enter the Phone Number")
      .matches(phoneRegExp, "Phone Number is Not Valid")
  });
  const [user, setUser] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const userData = sessionStorage.getItem("user_info");
    const newData = JSON.parse(userData);
    setUser(newData);
  }, []);
  const initial = {
    email: user?.email,
    password: user?.password,
    first_name: user?.firstName,
    last_name: user?.lastName,
    phone: user?.phone
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initial}
      onSubmit={(values) => {
        // eslint-disable-next-line no-undef
        var md5 = require("md5");
        const validPass = CheckMd5(values.password);
        console.log("validPass :>> ", validPass);
        if (validPass) {
          console.log("values.password :>> ", values.password);
          MerchantService.updateUser(user.id, values)
            .then((res) => {
              toaster({ title: res.result.message, description: "Please login again" });
              sessionStorage.clear();
              navigate("/login");
            })
            .catch((err) => {
              console.log("err :>> ", err);
            });
        } else {
          const hashedPassword = md5(values.password);
          console.log("hashedPassword", hashedPassword);
          MerchantService.updateUser(user.id, { ...values, password: hashedPassword })
            .then((res) => {
              toaster({ title: res.result.message, description: "Please login again" });
              sessionStorage.clear();
              navigate("/login");
            })
            .catch((err) => {
              console.log("err :>> ", err);
            });
        }
      }}
      validationSchema={schema}>
      {({ handleChange, handleSubmit, handleBlur, values, errors }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Flex
              minH={"100vh"}
              align={"center"}
              justify={"center"}
              bg={useColorModeValue("gray.50", "gray.800")}>
              <Stack
                spacing={4}
                w={"full"}
                maxW={"md"}
                bg={useColorModeValue("white", "gray.700")}
                rounded={"xl"}
                boxShadow={"lg"}
                p={6}
                my={12}>
                <Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
                  User Profile Edit
                </Heading>
                <FormControl id="first_name" isRequired isInvalid={errors.first_name}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    placeholder="eg: Steve"
                    _placeholder={{ color: "gray.500" }}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                  />
                  {errors.first_name && (
                    <FormHelperText color={"#F56565"}>{errors.first_name}</FormHelperText>
                  )}
                </FormControl>

                <FormControl id="last_name" isRequired isInvalid={errors.last_name}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    placeholder="eg: smith"
                    _placeholder={{ color: "gray.500" }}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                  />
                  {errors.last_name && (
                    <FormHelperText color={"#F56565"}>{errors.last_name}</FormHelperText>
                  )}
                </FormControl>
                <FormControl id="phone" isRequired isInvalid={errors.phone}>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    placeholder="phone"
                    _placeholder={{ color: "gray.500" }}
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    // isValid={touched.phone && !errors.phone}
                  />
                  {errors.phone && (
                    <FormHelperText color={"#F56565"}>{errors.phone}</FormHelperText>
                  )}
                </FormControl>
                <FormControl id="email" isRequired isInvalid={errors.email}>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    placeholder="your-email@example.com"
                    _placeholder={{ color: "gray.500" }}
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isDisabled
                    // isValid={touched.email && !errors.email}
                  />
                  {errors.email && (
                    <FormHelperText color={"#F56565"}>{errors.email}</FormHelperText>
                  )}
                </FormControl>
                <FormControl id="password" isRequired isInvalid={errors.password}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    placeholder="password"
                    _placeholder={{ color: "gray.500" }}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    // isValid={touched.password && !errors.password}
                  />
                  {errors.password && (
                    <FormHelperText color={"#F56565"}>{errors.password}</FormHelperText>
                  )}
                </FormControl>
                <Stack spacing={6} direction={["column", "row"]}>
                  <Button
                    colorScheme="orange"
                    variant="outline"
                    w="full"
                    onClick={() => navigate("/admin/dashboard")}>
                    Cancel
                  </Button>
                  <Button colorScheme="orange" color={"white"} w="full" type="submit">
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </Flex>
          </form>
        </>
      )}
    </Formik>
  );
}
