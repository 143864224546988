import React, { useEffect, useState } from "react";
import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  useEffect(() => {
    const userData = sessionStorage.getItem("user_info");
    const newData = JSON.parse(userData);
    setUser(newData);
  }, []);
  return (
    <>
      <Center py={6}>
        <Box
          maxW={"270px"}
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          boxShadow={"2xl"}
          rounded={"md"}
          overflow={"hidden"}>
          <Image
            h={"120px"}
            w={"full"}
            src={
              "https://images.unsplash.com/photo-1612865547334-09cb8cb455da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
            }
            objectFit={"cover"}
          />
          <Flex justify={"center"} mt={-12}>
            <Avatar
              size={"xl"}
              alt={"Author"}
              css={{
                border: "2px solid white"
              }}
            />
          </Flex>

          <Box p={6}>
            <Stack spacing={0} align={"center"} mb={5}>
              <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                {user?.firstName} {user?.lastName}
              </Heading>
              <Text color={"gray.500"} className="email-overflow">
                {user?.email}
              </Text>
            </Stack>

            <Button
              w={"full"}
              mt={8}
              // bg={useColorModeValue("#151f21", "gray.900")}
              colorScheme="orange"
              variant="outline"
              rounded={"md"}
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg"
              }}
              onClick={() => {
                navigate("/admin/profile/edit");
              }}>
              Edit Profile
            </Button>
          </Box>
        </Box>
      </Center>
    </>
  );
};

export default Profile;
