import React, { useState } from "react";
import { useEffect } from "react";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyDCk1sa4xn6rlPDJll10RMheTLlH-3vjR0");
Geocode.enableDebug();

const Map = ({ yourAddress }) => {
  const [mapPosition, setMapPosition] = useState({ latitude: 0.0, longitude: 0.0 });
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setMapPosition({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      console.log(mapPosition);
    });
  }, []);
  useEffect(() => {
    Geocode.fromLatLng(mapPosition.latitude, mapPosition.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          area = getArea(addressArray),
          state = getState(addressArray),
          pincode = getPincode(addressArray),
          country = getCountry(addressArray);
        console.log("city", response, city, area, state, country);

        yourAddress({
          address1: address ? address : "",
          address2: address ? address : "",
          state: state ? state : "",
          city: city ? city : "",
          country: country ? country : "",
          zipcode: pincode ? pincode : ""
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }, [mapPosition]);
  const getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "administrative_area_level_2" === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  const getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  const getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
          country = addressArray[i].long_name;
          return country;
        }
      }
    }
  };
  const getPincode = (addressArray) => {
    let pincode = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && "postal_code" === addressArray[i].types[0]) {
          pincode = addressArray[i].long_name;
          return pincode;
        }
      }
    }
  };

  return <></>;
};
export default Map;
