/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Wrap,
  WrapItem,
  Box,
  Heading,
  Text,
  Flex,
  Icon,
  Divider,
  Input,
  Stack,
  useColorModeValue,
  Switch
} from "@chakra-ui/react";
import {
  MdLocationOn,
  MdSettingsPhone,
  MdOutlineEmail,
  MdOutlineDeliveryDining,
  MdAvTimer
} from "react-icons/md";
import storeService from "../../services/api/storeService";
import MerchantService from "../../services/api/merchantService";
import { useCallback } from "react";
import { useAppContext } from "../../hooks/AppContext";
import phoneNumberFormatter from "../../hooks/PhoneNumberFormatter";
import StoreImage from "./store.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TokenExpired from "../Alerts/TokenExpired";

const iconColor = "#808080";
export default function StoreList() {
  const [data, setData] = useState();
  const [filter, setFilter] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [sample, updateState] = React.useState(0);
  const [error, setError] = useState();
  const forceUpdate = useCallback(() => {
    if (searchText === "") {
      // filterData(data);
    } else {
      filterData(searchText);
    }
    updateState(sample + 1);
  }, [searchText, sample]);

  useEffect(() => {
    storeService
      .getAllStore()
      .then((res) => {
        setData(res.data);
        setFilter(res.data);
      })
      .catch((err) => {
        if (err.status === 401) {
          setError(err);
        }
      });
  }, []);

  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
  };
  const excludeColumns = ["storeId"];
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setFilter(data);
    else {
      const filteredData = data.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key]?.toString()?.toLowerCase().includes(lowercasedValue)
        );
      });
      setFilter(filteredData);
    }
  };

  return (
    <>
      {error ? (
        <TokenExpired />
      ) : (
        <>
          <Flex justify="center" align="center">
            <Stack
              spacing={4}
              w={"full"}
              maxW={"80%"}
              bg={useColorModeValue("white", "gray.700")}
              rounded={"xl"}
              boxShadow={"lg"}
              p={6}
              mb={12}>
              <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
                Search Store here
              </Heading>

              <Input
                placeholder="Type to search Store Name, Email, Number, Address etc"
                _placeholder={{ color: "gray.500" }}
                value={searchText}
                onChange={(e) => handleChange(e.target.value)}
                size="lg"
              />
            </Stack>
          </Flex>

          <Wrap spacing="30px" justify="center">
            {filter &&
              filter.map((res) => (
                <StoreCard data={res} key={res.storeId} callBackListener={forceUpdate} />
              ))}
          </Wrap>
          <Flex justify="center" align="center">
            {filter && filter.length === 0 ? (
              <>
                <Box textAlign="center" py={10} px={6}>
                  <Heading as="h2" size="xl" mt={6} mb={2}>
                    No Search Results Found
                  </Heading>
                </Box>
              </>
            ) : (
              ""
            )}
          </Flex>
        </>
      )}
    </>
  );
}

const StoreCard = ({ data, callBackListener }) => {
  const regex = new RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?");
  const urlPatternValidation = (URL) => {
    return URL !== null && URL !== "" && regex.test(URL);
  };

  const { toaster } = useAppContext();
  const [currentStatus, setCurrentStatus] = useState(data.accountStatus);
  const [switchStatus, setSwitchStatus] = useState(false);
  const activeInActiveStore = (storeId, statusCode) => {
    setSwitchStatus(true);
    MerchantService.updateStoreStatus(storeId, statusCode)
      .then((res) => {
        toaster({
          title: `${
            statusCode === 1 ? "Store Activeted Successfully" : "Store de-activeted Successfully"
          }`
        });
        setSwitchStatus(false);
        setCurrentStatus(res.data.accountStatus);
        callBackListener();
      })
      .catch(() => {
        toaster({
          title: "Error",
          description: "Something went wrong!",
          status: "error"
        });
        setSwitchStatus(false);
        callBackListener();
      });
  };
  return (
    <WrapItem boxShadow="dark-lg" borderRadius="10" w="320px" align="center" bg="white">
      <Box
        w="sm"
        mx="auto"
        bg="white"
        _dark={{
          bg: "gray.800"
        }}
        rounded="lg"
        overflow="hidden">
        {/* <Image w="full" fit="cover" h="200" objectPosition="center" src={(urlPatternValidation(data.logoUrl)) ? data.logoUrl : StoreImage} alt="" /> */}
        <div
          style={
            {
              // width: '100%',
              // maxHeight: 200,
            }
          }>
          <LazyLoadImage
            src={urlPatternValidation(data.logoUrl) ? data.logoUrl : StoreImage}
            width={320}
            height={320}
            alt="Image Alt"
            className="lazy-image"
          />
        </div>
        <Box py={4} px={6} alignItems="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="gray.800"
            _dark={{
              color: "white"
            }}
            mb={2}>
            {data.storeName}
          </Text>
          <MainText data={`${data.address1}`} icon={MdLocationOn} />
          <MainText data={phoneNumberFormatter(data.phone)} icon={MdSettingsPhone} />
          <Divider />
          <SubText data={data.email} icon={MdOutlineEmail} type="email" />
          <SubText text="radius" data={data.radius} icon={MdOutlineDeliveryDining} type="data" />
          <SubText data={data.website} icon={MdAvTimer} type="website" />
        </Box>
        <Flex mb={4} alignItems="center">
          <Switch
            colorScheme={currentStatus && currentStatus === 4 ? "linkedin" : "red"}
            ml={6}
            isDisabled={switchStatus}
            id="store-active"
            isChecked={!(currentStatus && currentStatus !== 1)}
            onChange={() =>
              activeInActiveStore(data.storeId, currentStatus && currentStatus === 1 ? 2 : 1)
            }
          />
          <Text fontSize="sm" fontWeight="bold" color="gray.800" ml={2}>
            {currentStatus && currentStatus !== 1 ? "In-Active!" : "Active!"}{" "}
          </Text>
        </Flex>
      </Box>
    </WrapItem>
  );
};

const MainText = ({ data, icon }) => {
  return (
    <Text
      fontSize="xl"
      fontWeight="bold"
      color="gray.800"
      _dark={{
        color: "white"
      }}
      mb={2}
      align="left">
      <Text px={1} fontSize="sm">
        <Icon as={icon} h={6} w={6} mr={2} color={iconColor} /> {data}
      </Text>
    </Text>
  );
};

const SubText = ({ data, icon, text, type }) => {
  const mailFormat = `mailto: ${data}`;
  const webFormat = `https://${data}`;
  return (
    <Flex
      alignItems="center"
      mt={4}
      color="gray.700"
      _dark={{
        color: "gray.200"
      }}>
      <Icon as={icon} h={6} w={6} mr={2} color={iconColor} />
      <Text px={1} fontSize="sm">
        {type === "email" ? <a href={mailFormat}>{data}</a> : ""}
        {type === "website" ? (
          <a href={webFormat} target="_blank" rel="noreferrer">
            {data}
          </a>
        ) : (
          ""
        )}
        {type === "data" ? (
          <>
            {text} : {data}
          </>
        ) : (
          ""
        )}
      </Text>
    </Flex>
  );
};
