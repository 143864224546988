import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody, Box, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
export default function TokenExpired() {
  const [open] = useState(true);
  const navigate = useNavigate();
  return (
    <>
      <Modal isOpen={open} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign="center" py={10} px={6}>
              {/* <Heading
                display="inline-block"
                as="h2"
                size="2xl"
                bgGradient="linear(to-r, teal.400, teal.600)"
                backgroundClip="text">
                401
              </Heading> */}
              <Text fontSize="18px" mt={3} mb={2}>
                Session is Expired
              </Text>
              <Text color={"gray.500"} mb={6}>
                Your Session has timeout. To working continue, sign in again
              </Text>

              <Button
                colorScheme="orange"
                // bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                color="white"
                variant="solid"
                onClick={() => {
                  navigate("/login");
                }}>
                Go to Login Page
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
