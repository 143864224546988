// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import CONSTANTS from "../shared/constants";

const app = initializeApp(CONSTANTS.firebaseConfig);

requestPermission();
function requestPermission() {
  if (typeof Notification !== "undefined") {
    if (Notification && Notification.permission === "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.log("Unable to get permission to notify");
        }
      });
    }
  }
  if (typeof Notification === "function") {
    if (Notification && Notification.permission === "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.info("Notification permission granted. function");
        } else {
          console.info("Unable to get permission to notify function");
        }
      });
    }
  }
}
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

const requestForToken = () => {
  // useEffect(() => {
  //   console.log("notication useeffect");
  //   if (!("Notification" in window)) {
  //     console.log("This browser does not support notifications.");
  //   } else {
  //     requestPermission();
  //   }
  // }, []);

  return messaging.then((mess) => {
    return getToken(mess, {
      vapidKey: `BMtHlnoiev6JzUQteMTOHiqEN4mThc3rixmeh5qQXkSTqZHJWKU-GutEHuqyd4D3lOK6ITxw0JeXrSlBzljYIj4`
    })
      .then((currentToken) => {
        if (currentToken) {
          // console.log("current token for client: ", currentToken);
          // Perform any other neccessary action with the token
          return currentToken;
        } else {
          // Show permission request UI
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  });
};
const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      console.info("onMessageListener--->", payload);
      resolve(payload);
    });
  });
export { app, messaging, requestForToken, onMessageListener };
