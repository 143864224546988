/* eslint-disable react/no-children-prop */
import React, { useEffect, useState, useRef } from "react";
import { Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import MerchantService from "../../services/api/merchantService";
import Map from "./Map";
import StatesData from "../../assets/data/states";
import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  InputLeftElement,
  InputGroup,
  Select
} from "@chakra-ui/react";

export default function StoreInquiryForm() {
  const inputRef = useRef(null);
  const phoneRegExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  const nameRegExp = /^[A-Za-z ]*$/;
  const zipCodeRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(40, "Must be 40 characters or less")
      .matches(nameRegExp, "Please enter valid name")
      .required("Please Enter First Name "),
    last_name: Yup.string()
      .min(1, "Must be 1 characters or more")
      .max(40, "Must be 40 characters or less")
      .matches(nameRegExp, "Please Enter Valid Name")
      .required("Please Enter Last Name"),
    phone: Yup.string()
      .matches(phoneRegExp, "Please Enter Valid Contact Number")
      .required("Please Enter Contact Number"),
    email: Yup.string().email("Email is not valid").required("Please Enter Email Address"),
    store_name: Yup.string()
      .min(5, "Must be 5 characters or more")
      .max(150, "Must be 150 characters or less")
      .required("Please Enter Store Name"),
    storecontact: Yup.string()
      .matches(phoneRegExp, "Please Enter Valid Contact Number")
      .required("Please Enter Store Phone Number"),
    address1: Yup.string()
      .min(5, "Must be 5 characters or more")
      .max(150, "Must be 150 characters or less")
      .required("Please Enter Store Street Address"),
    zipcode: Yup.string()
      .matches(zipCodeRegExp, "Please Enter Valid Zipcode")
      .required("Please Enter Zipcode"),
    city: Yup.string().required("Please Select City"),
    state: Yup.string().required("Please Select State"),
    country: Yup.string().required("Please Select Country")
  });
  const [googleMapAddress, setGoogleMapAddress] = useState({
    address1: "",
    state: "",
    city: "",
    country: "",
    zipcode: ""
  });
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    store_name: "",
    storecontact: "",
    address1: "",
    country: "",
    zipcode: googleMapAddress && googleMapAddress.zipcode
  });

  useEffect(() => {
    delete googleMapAddress.address2;
    delete googleMapAddress.state;
    const value = { ...initialValues, ...googleMapAddress };
    console.log("value", value);
    setInitialValues(value);
  }, [googleMapAddress]);

  const navigate = useNavigate();

  const navigateToEmailSubmition = (dataresult) => {
    console.log("dataresult=>", dataresult.data.email);
    navigate("/emailconfirmation", {
      state: {
        email: `${dataresult.data.email}`
      }
    });
  };
  async function submitStoreInfo(storeInfo) {
    console.log("storeInfo :>> ", storeInfo);
    MerchantService.storeEnquiry(storeInfo)
      .then(
        (data) => {
          console.log("data :>> ", data);
          navigateToEmailSubmition(data);
        },
        (fail) => {
          console.error(fail);
        }
      )
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }

  const stateRef = useRef(null);
  const cityRef = useRef(null);
  const zipRef = useRef(null);
  const countryRef = useRef(null);
  useEffect(() => {
    console.log("googleMapAddress", googleMapAddress);
    inputRef.current.value = googleMapAddress.address1;
    zipRef.current.value = googleMapAddress.zipcode;
  }, [googleMapAddress]);
  return (
    <>
      <Col md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} sm={{ span: 8, offset: 2 }}>
        <Card>
          <Card.Header>
            <div className="inquiry-form-header">Store Owner Information</div>
          </Card.Header>
          <Card.Body className="mx-3">
            <div className="info-header mb-4">* All Fields are mandatory</div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                submitStoreInfo(values);
              }}>
              {({ handleSubmit, values, handleChange, handleBlur, errors }) => (
                <form name="inquiryform" onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md="6">
                      <FormControl id="first_name" isRequired isInvalid={errors.first_name}>
                        <FormLabel>First Name</FormLabel>
                        <Input
                          placeholder="eg: Steve"
                          _placeholder={{ color: "gray.500" }}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.first_name}
                        />
                        {errors.first_name && (
                          <FormHelperText color={"#F56565"}>{errors.first_name}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                    <Col md="6">
                      <FormControl id="last_name" isRequired isInvalid={errors.last_name}>
                        <FormLabel>Last Name</FormLabel>
                        <Input
                          placeholder="eg: j"
                          _placeholder={{ color: "gray.500" }}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                        />
                        {errors.last_name && (
                          <FormHelperText color={"#F56565"}>{errors.last_name}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">
                      <FormControl id="phone" isRequired isInvalid={errors.phone}>
                        <FormLabel>Phone Number</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdPhone color="gray" />
                          </InputLeftElement>
                          <Input
                            placeholder="Phone Number"
                            _placeholder={{ color: "gray.500" }}
                            type="tel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                          />
                        </InputGroup>
                        {errors.phone && (
                          <FormHelperText color={"#F56565"}>{errors.phone}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                    <Col md="6">
                      <FormControl id="email" isRequired isInvalid={errors.email}>
                        <FormLabel>Email</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdEmail color="gray" />
                          </InputLeftElement>
                          <Input
                            placeholder="Eg : sample@email.com"
                            _placeholder={{ color: "gray.500" }}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </InputGroup>
                        {errors.email && (
                          <FormHelperText color={"#F56565"}>{errors.email}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">
                      <FormControl id="store_name" isRequired isInvalid={errors.store_name}>
                        <FormLabel>Store Name</FormLabel>
                        <InputGroup>
                          <Input
                            placeholder="Phone Number"
                            _placeholder={{ color: "gray.500" }}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.store_name}
                          />
                        </InputGroup>
                        {errors.store_name && (
                          <FormHelperText color={"#F56565"}>{errors.store_name}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                    <Col md="6">
                      <FormControl id="storecontact" isRequired isInvalid={errors.storecontact}>
                        <FormLabel>Store Phone Number</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdPhone color="gray" />
                          </InputLeftElement>
                          <Input
                            placeholder="Store Phone Number"
                            _placeholder={{ color: "gray.500" }}
                            type="tel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.storecontact}
                          />
                        </InputGroup>
                        {errors.storecontact && (
                          <FormHelperText color={"#F56565"}>{errors.storecontact}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <FormControl id="address1" isRequired isInvalid={errors.address1}>
                      <FormLabel>Store Street Address</FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <MdLocationOn color="gray" />
                        </InputLeftElement>
                        <Input
                          ref={inputRef}
                          placeholder="Store Street Address"
                          _placeholder={{ color: "gray.500" }}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address1}
                        />
                      </InputGroup>
                      {errors.address1 && (
                        <FormHelperText color={"#F56565"}>{errors.address1}</FormHelperText>
                      )}
                    </FormControl>
                  </Row>

                  <Row className="mb-3">
                    <Col md="6">
                      <FormControl id="state" isRequired isInvalid={errors.state}>
                        <FormLabel>State</FormLabel>
                        <Select
                          ref={stateRef}
                          placeholder="Select State"
                          _placeholder={{ color: "gray.500" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}>
                          {StatesData &&
                            Object.keys(StatesData).map((key) => (
                              <option value={key} key={key}>
                                {key}
                              </option>
                            ))}
                        </Select>
                        {errors.state && (
                          <FormHelperText color={"#F56565"}>{errors.state}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                    <Col md="6">
                      <FormControl id="city" isRequired isInvalid={errors.city}>
                        <FormLabel>City</FormLabel>
                        <Select
                          ref={cityRef}
                          placeholder="Select City"
                          _placeholder={{ color: "gray.500" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}>
                          {values.state &&
                            StatesData[values.state].map((key, index) => (
                              <option
                                value={key}
                                key={index}
                                style={{ textTransform: "capitalize" }}>
                                {key.toLowerCase()}
                              </option>
                            ))}
                        </Select>
                        {errors.city && (
                          <FormHelperText color={"#F56565"}>{errors.city}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">
                      <FormControl id="country" isRequired isInvalid={errors.country}>
                        <FormLabel>Country</FormLabel>
                        <Input
                          ref={countryRef}
                          placeholder="Country"
                          _placeholder={{ color: "gray.500" }}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                        />
                        {errors.country && (
                          <FormHelperText color={"#F56565"}>{errors.country}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                    <Col md="6">
                      <FormControl id="zipcode" isRequired isInvalid={errors.zipcode}>
                        <FormLabel>ZipCode</FormLabel>
                        <Input
                          ref={zipRef}
                          placeholder="Zipcode"
                          _placeholder={{ color: "gray.500" }}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zipcode}
                        />
                        {errors.zipcode && (
                          <FormHelperText color={"#F56565"}>{errors.zipcode}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col align="center">
                      <Button type="submit" className="text-center" colorScheme={"orange"}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
      <Map
        yourAddress={(address) => {
          console.log("your Address : ", address);
          setGoogleMapAddress(address);
        }}
      />
    </>
  );
}
