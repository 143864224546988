import React, { useEffect } from "react";
import { Outlet, useLocation, Navigate, useNavigate, NavLink } from "react-router-dom";
import adminLogo from "../assets/logo/HotspotLogo.webp";
import useAuth from "../hooks/useAuth";
import {
  BsCart,
  BsListStars,
  BsDiagram2,
  BsDiagram3,
  BsBag,
  BsShop,
  BsShopWindow,
  BsPeople
} from "react-icons/bs";
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Text,
  Drawer,
  DrawerContent,
  IconButton,
  useDisclosure,
  DrawerOverlay,
  useColorModeValue
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider
} from "@chakra-ui/react";
import { AiOutlineLogout, AiOutlineAppstore } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import Notification from "../services/notification";
import capitalizeSentance from "../hooks/CapitalizeSentence";

const AdminDashboardLayout = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const user = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const userData = sessionStorage.getItem("user_info");
  const newData = JSON.parse(userData);
  useEffect(() => {
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    }
  }, []);
  if (!user) {
    console.log("Redirect to login ", location);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const Role = () => {
    if (newData.role === "HOTSPOT_ADMIN") {
      return "Hotspot Admin";
    } else if (newData.role === "HOTSPOT_FINANCE") {
      return "Finance";
    } else {
      return "Marketing";
    }
  };
  return (
    <>
      <Notification />
      <Box as="section" bg={"#eeeeee"} minH="100vh">
        <SidebarContent display={{ base: "none", md: "unset" }} />
        <Drawer isOpen={isOpen} onClose={onClose} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarContent w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>
        <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
          <Flex
            as="header"
            align="center"
            justify={{ base: "space-between", md: "space-between" }}
            w="full"
            px="4"
            borderBottomWidth="1px"
            borderColor={useColorModeValue("inherit", "gray.700")}
            bg={useColorModeValue("white", "gray.800")}
            boxShadow="sm"
            h="14">
            <IconButton
              aria-label="Menu"
              display={{ base: "inline-flex", md: "none" }}
              onClick={onOpen}
              icon={<FiMenu />}
              size="md"
            />
            <Text></Text>

            <Flex align="center">
              {/* <Button onClick={toggleColorMode} variant="outline">
                {colorMode === "light" ? <BsMoonStarsFill /> : <BsSun />}
              </Button> */}
              <Menu>
                <MenuButton>
                  <Flex align="center" cursor="pointer">
                    <Box ml="4">
                      <Text fontWeight="bold">{capitalizeSentance(newData.firstName)}</Text>
                      <Text fontSize="sm">{Role()}</Text>
                    </Box>
                    <Avatar ml="4" size="sm" />
                  </Flex>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      navigate("/admin/profile/edit");
                    }}>
                    <Flex align="center" cursor="pointer">
                      <Avatar ml="4" size="sm" />
                      <Box ml="4">
                        <Text fontWeight="normal">Edit Profile</Text>
                      </Box>
                    </Flex>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      sessionStorage.clear();
                      navigate("/login");
                    }}>
                    <Flex align="center" cursor="pointer">
                      <AiOutlineLogout size={30} className="logout-icon" />
                      <Box ml="4">
                        <Text fontWeight="normal" colorScheme="gray">
                          LogOut
                        </Text>
                      </Box>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
          <Box as="main" p={3} minH="25rem" bg={"#eeeeee"}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AdminDashboardLayout;

const userData = sessionStorage.getItem("user_info");
const newData = JSON.parse(userData);

const isAdmin = newData?.role === "HOTSPOT_ADMIN";
const isMarketing = newData?.role === "HOTSPOT_MARKETING";

const SidebarContent = ({ ...props }) => (
  <Box
    as="nav"
    pos="fixed"
    top="0"
    left="0"
    zIndex="sticky"
    h="full"
    pb="10"
    overflowX="hidden"
    overflowY="auto"
    bg={useColorModeValue("white", "gray.800")}
    borderColor={useColorModeValue("inherit", "gray.700")}
    borderRightWidth="1px"
    w="60"
    {...props}>
    <Flex px="4" align="center">
      <div className="logo">
        <img src={adminLogo} alt="" />
      </div>
    </Flex>
    <Flex direction="column" as="nav" fontSize="md" color="gray.600" aria-label="Main Navigation">
      <NavLink
        className={({ isActive }) => (isActive ? "active-nav-link" : "")}
        to={`/admin/dashboard`}>
        <NavItem icon={AiOutlineAppstore}> Dashboard</NavItem>
      </NavLink>
      {(isAdmin || isMarketing) && (
        <NavLink
          className={({ isActive }) => (isActive ? "active-nav-link" : "")}
          to={`/admin/enquirylist`}>
          <NavItem icon={BsListStars}>Inquiry list</NavItem>
        </NavLink>
      )}

      {isAdmin && (
        <>
          <NavLink
            className={({ isActive }) => (isActive ? "active-nav-link" : "")}
            to={`/admin/categories`}>
            <NavItem icon={BsDiagram2}>Category List</NavItem>
          </NavLink>

          <NavLink
            className={({ isActive }) => (isActive ? "active-nav-link" : "")}
            to={`/admin/sku`}>
            <NavItem icon={BsDiagram3}>Sub Category</NavItem>
          </NavLink>

          <NavLink
            className={({ isActive }) => (isActive ? "active-nav-link" : "")}
            to={`/admin/productlist`}>
            <NavItem icon={BsBag}>UPC products List</NavItem>
          </NavLink>

          <NavLink
            className={({ isActive }) => (isActive ? "active-nav-link" : "")}
            to={`/admin/order`}>
            <NavItem icon={BsCart}>Orders</NavItem>
          </NavLink>
        </>
      )}
      {(isAdmin || isMarketing) && (
        <>
          <Accordion allowToggle className="adminLayout-navBar-border ">
            <AccordionItem style={{ borderStyle: "none" }}>
              <>
                <AccordionButton>
                  <Flex
                    align="center"
                    px="0"
                    py="1"
                    cursor="pointer"
                    role="group"
                    fontWeight="semibold"
                    transition=".15s ease"
                    color={useColorModeValue("inherit", "gray.400")}
                    _hover={{
                      bg: useColorModeValue("gray.100", "gray.900"),
                      color: useColorModeValue("gray.900", "gray.200")
                    }}>
                    <Icon mx="2" boxSize="4" as={BsShop} />
                    Store
                  </Flex>
                  <AccordionIcon ml="5" />
                </AccordionButton>
              </>
              <AccordionPanel className="sidebar-accordiant-nav">
                <NavLink
                  className={({ isActive }) => (isActive ? "active-nav-link" : "")}
                  to={`/admin/partner/store`}>
                  <NavItem icon={BsShop} sub>
                    Partner Store
                  </NavItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active-nav-link" : "")}
                  to={`/admin/partner/invite`}>
                  <NavItem icon={BsShopWindow} sub>
                    Invite Store
                  </NavItem>
                </NavLink>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      )}
      {isAdmin && (
        <NavLink
          className={({ isActive }) => (isActive ? "active-nav-link" : "")}
          to={`/admin/users`}>
          <NavItem icon={BsPeople}>User Management</NavItem>
        </NavLink>
      )}
    </Flex>
  </Box>
);

const NavItem = (props) => {
  const color = useColorModeValue("gray.600", "gray.300");

  // eslint-disable-next-line react/prop-types
  const { icon, children } = props;

  return (
    <Flex
      align="center"
      px="4"
      py="3"
      cursor="pointer"
      role="group"
      fontWeight="semibold"
      transition=".15s ease"
      color={useColorModeValue("inherit", "gray.400")}
      _hover={{
        bg: useColorModeValue("gray.100", "gray.900"),
        color: useColorModeValue("gray.900", "gray.200")
      }}
      className="adminLayout-navBar-border">
      {icon && (
        <Icon
          mx="2"
          boxSize="4"
          _groupHover={{
            color: color
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};
