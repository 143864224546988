import React, { useState, useMemo, createContext, useContext } from "react";
import MerchantService from "../services/api/merchantService";
import { useToast } from "@chakra-ui/react";

// eslint-disable-next-line react/prop-types
export const AppContextProvider = ({ children }) => {
  const [token, setToken] = useState();
  const toast = useToast();
  const [userInfo, setUserInfo] = useState();

  const login = (payload, nav, loginFailed) => {
    MerchantService.login(payload)
      .then((res) => {
        if (res.result.success) {
          setToken(res.data.token);
          setUserInfo({ ...res.data.user_info });
          console.log(userInfo);
          sessionStorage.setItem("token", res.data.token);
          const newObj = JSON.stringify(res.data.user_info);
          sessionStorage.setItem("user_info", newObj);
          nav();
        } else {
          console.log(res.result.message);
          loginFailed();
        }
      })
      .catch((err) => {
        console.log(err);
        loginFailed();
      });
  };
  const logOut = () => {};
  const toaster = ({ title, description, status }) => {
    toast({
      title: title,
      description: description ? description : "",
      status: status ? status : "success",
      duration: 4000,
      isClosable: true,
      position: "top-right"
    });
  };
  const memoedValue = useMemo(
    () => ({
      login,
      logOut,
      token,
      toaster
    }),
    []
  );
  return <AppContext.Provider value={memoedValue}>{children}</AppContext.Provider>;
};

export function useAppContext() {
  const ctx = useContext(AppContext);
  return {
    ...ctx
  };
}

export const AppContext = createContext();
export default AppContext;
