import httpClient from "../../shared/lib/httpClient";
import AuthToken from "./authToken";
// services/api/message.js

function get(id) {
  return httpClient({
    url: `v1/store/invitation/${id}`,
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
}

function create(payload) {
  return httpClient({
    url: "/register",
    method: "POST",
    data: {
      ...payload
    },
    headers: {
      Authorization: AuthToken
    }
  });
}

function login(payload) {
  return httpClient({
    url: "/o/v1/login",
    method: "POST",
    data: {
      ...payload
    }
  });
}
function forgotPassword(payload) {
  return httpClient({
    url: "/o/v1/forgotPassword",
    method: "POST",
    data: {
      ...payload
    }
  });
}

function storeEnquiry(payload) {
  return httpClient({
    url: "o/v1/store/enquiry",
    method: "POST",
    data: {
      ...payload
    },
    headers: {
      Authorization: AuthToken
    }
  });
}
function updateUser(id, payload) {
  return httpClient({
    url: `v1/user/${id}`,
    method: "PUT",
    data: {
      ...payload
    },
    headers: {
      Authorization: AuthToken
    }
  });
}

function storeSignUp(payload) {
  return httpClient({
    url: "o/v1/store",
    method: "POST",
    data: {
      ...payload
    },
    headers: {
      Authorization: AuthToken
    }
  });
}

function updateStoreStatus(id, value) {
  return httpClient({
    url: `v1/store/${id}`,
    method: "PATCH",
    data: {
      status: value
    },
    headers: {
      Authorization: AuthToken
    }
  });
}

const MerchantService = {
  get,
  create,
  login,
  storeEnquiry,
  storeSignUp,
  updateUser,
  updateStoreStatus,
  forgotPassword
};

export default MerchantService;
