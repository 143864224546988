/* eslint-disable react/no-unknown-property */
import React from "react";
import { Formik } from "formik";
import { Row, Col, Form, Button } from "react-bootstrap";
import { InputText } from "../../components/form";
// import {
//     faPencil,
//     faTrash
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from "react";
import styled from "styled-components";

// eslint-disable-next-line react/prop-types
const MarkHoliday = ({ doneMarkHoliday }) => {
  const current = new Date();
  const currentDate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;

  console.log(currentDate);

  const initialValues = {
    date: "",
    from: "10:30",
    to: "11:30",
    reason: "",
    id: ""
  };

  const [form, setForm] = useState(initialValues);
  // setForm({...form, date: currentDate})

  const [holidays, setHolidays] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const onChangeInput = (e) => {
    console.log(e.target.value);
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    //e.preventDefault();
    console.log(`date ${e}`);
    if (form.date === "" || form.from === "" || form.to === "" || form.reason === "") {
      //alert"Please fill in the blanks!");
    } else {
      form.id = isEdit ? form.id : holidays.length + 1;
      setHolidays([...holidays, form]);
      console.log(`holidaylist ${holidays}`);
      setForm(initialValues);
      setIsEdit(false);
    }
  };

  const [filteredText] = useState("");

  const filteredData = holidays.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toString().toLowerCase().includes(filteredText.toLocaleLowerCase())
    );
  });

  const handleDelete = (id) => {
    console.log(id);
    const filteredItems = holidays.filter((item) => item.id !== id);
    setHolidays(filteredItems);
  };

  const handleEdit = (id) => {
    const filteredItems = holidays.filter((item) => item.id !== id);
    const selectedItem = holidays.find((item) => item.id === id);
    setHolidays(filteredItems);
    setForm(selectedItem);
    setIsEdit(true);
  };

  const buttonClickHandler = () => {
    holidays.forEach(function (v) {
      delete v.id;
    });
    doneMarkHoliday(holidays);
  };

  const StyledButton = styled(Button)`
    &:hover {
    }
  `;

  return (
    <>
      <Col md={{ span: 9, offset: 2 }} lg={{ span: 9, offset: 2 }}>
        <Row className="mt-5">
          <Col lg={{ span: 7, offset: 2 }}>
            <div className="d-flex justify-content-center">
              <h2>Mark Holidays</h2>
            </div>
            <h6 className="d-flex justify-content-center text-muted mb-5">
              (Select days and time to mark as holiday)
            </h6>
          </Col>
        </Row>

        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={async (values) => {
            // await sleep(500);
            //alertJSON.stringify(values, null, 2));
            handleSubmit(values);
          }}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={2}>
                  <InputText
                    onChange={onChangeInput}
                    label="Date"
                    name="date"
                    type="date"
                    value={form.date}
                    placeholder="DD/MM/YYYY"
                  />
                </Col>

                <Col className="form-group ">
                  <InputText
                    onChange={onChangeInput}
                    label="From"
                    name="from"
                    type="time"
                    value={form.from}
                    placeholder="10:00 AM"
                  />
                </Col>

                <Col className="form-group ">
                  <InputText
                    label="To"
                    onChange={onChangeInput}
                    name="to"
                    value={form.to}
                    type="time"
                    placeholder="11:00 PM"
                  />
                </Col>

                <Col xs={4} className="form-group">
                  <InputText
                    onChange={onChangeInput}
                    label="Reason"
                    name="reason"
                    value={form.reason}
                    type="text"
                    placeholder=""
                  />
                </Col>

                <Col className="form-group mt-4">
                  <StyledButton
                    variant="outline-danger"
                    className="mt-1 fw-2"
                    size="lg"
                    style={{ backgroundColor: "transparent", color: "red" }}
                    type="submit">
                    {isEdit ? "Update Holiday" : "Add Holiday"}{" "}
                  </StyledButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <div className="border border mt-5 ">
          <table striped bordered hover className=" table table-borderless">
            {filteredData && (
              <thead className="bg-b">
                <tr className="table-secondary">
                  <th>Sr No</th>
                  <th>Day & Date</th>
                  <th>From</th>
                  <th>Till</th>
                  <th>Reason</th>
                  <th>Action</th>
                </tr>
              </thead>
            )}

            {filteredData &&
              filteredData.map((item) => {
                const { date, from, reason, to, id } = item;
                return (
                  <tbody key={item}>
                    <tr
                      style={{ backgroundColor: id % 2 == 0 ? "#F5F5F5" : "transparent" }}
                      key={id}>
                      <td>{id}</td>
                      <td>{date}</td>
                      <td>{from}</td>
                      <td>{to}</td>
                      <td>{reason}</td>
                      <td>
                        <span className="mx-2 text-warning" onClick={() => handleEdit(id)}>
                          {/* <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon> */}
                        </span>
                        <span className="mx-2 text-warning" onClick={() => handleDelete(id)}>
                          {/* <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> */}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
        <div className="form-group mt-5">
          <Row>
            <Col align="center">
              <Button type="Next" variant="primary" size="lg" onClick={buttonClickHandler}>
                Done
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};
export default MarkHoliday;
