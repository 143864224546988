/* eslint-disable react/prop-types */
import React from "react";
import { useField } from "formik";
import { Form } from "react-bootstrap";

const InputSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    /*  <div>
        <label htmlFor={props.id || props.name}>{label}</label>
        <select {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div> */

    <Form.Group controlId={props.id || props.name}>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        {...field}
        {...props}
        className={
          meta.touched && meta.error
            ? "form-control is-invalid"
            : meta.touched && !meta.error
            ? "is-valid"
            : ""
        }
      />
      {meta.touched && meta.error ? (
        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default InputSelect;
