import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import "../styles/layout/default.scss";
const Layout = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col
            md={{ span: 1 }}
            sm={4}
            className="default-leftContainer img-responsive d-none d-md-block"></Col>
          <Col style={{ marginBottom: 10, marginTop: 10 }}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Layout;
