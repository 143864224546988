import { Formik } from "formik";
import React, { useEffect } from "react";
import { Col, Form, FormControl, InputGroup } from "react-bootstrap";
// import {
//     faLocationDot,
//     faLocationCrosshairs
// } from "@fortawesome/free-solid-svg-icons";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Location = () => {
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }, []);

  const showPosition = (position) => {
    const { latitude, longitude } = position.coords;
    console.log(latitude, longitude);
  };
  return (
    <>
      <Col md={{ span: 6, offset: 3 }} lg={{ span: 5, offset: 3 }}>
        <h2 className="mt-5 mb-5">Address</h2>
        <Formik
          onSubmit={async () => {
            // await sleep(500);
            //alertJSON.stringify(values, null, 2));
          }}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Address</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    className="bg-transparent border border-end-0 border-danger"
                    id="basic-addon1">
                    {/* <FontAwesomeIcon className="color" /> */}
                  </InputGroup.Text>
                  <FormControl
                    className="border border-start-0 border-danger shadow-none"
                    placeholder="Location"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"></FormControl>
                  <InputGroup.Text
                    className="bg-danger text-white border-0  rounded-start"
                    id="basic-addon2">
                    <i>{/* <FontAwesomeIcon icon={faLocationCrosshairs}></FontAwesomeIcon> */}</i>
                  </InputGroup.Text>
                  <InputGroup.Text
                    className="bg-danger text-white border-0 border fw-bold"
                    id="basic-addon2">
                    Current Location (GPS)
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Col>
    </>
  );
};

export default Location;
