import httpClient from "../../shared/lib/httpClient";
import AuthToken from "./authToken";

const getOrdersList = (data = {}) => {
  return httpClient({
    url: "/v1/orders/count" + getParameters(data),
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
};
const getParameters = (data = {}) => {
  let parameters = "";
  if (Object.keys(data).length > 0) {
    parameters = "/?";
    if ("endDate" in data && "startDate" in data)
      parameters += `from=${data["startDate"]}&to=${data["endDate"]}`;
    if ("storeId" in data && data["storeId"] !== "All")
      parameters += `&store_id=${data["storeId"]}`;
  }
  return parameters;
};
const getGrossSaleList = (data) => {
  return httpClient({
    url: "/v1/orders/sale" + getParameters(data),
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
};
const getAvgPriceList = (data) => {
  return httpClient({
    url: "/v1/orders/avg_price" + getParameters(data),
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
};
const getSKURating = (data) => {
  return httpClient({
    url: "/v1/orders/topSKU" + getParameters(data),
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
};
const getAvgDeliveryTime = (data) => {
  return httpClient({
    url: "/v1/orders/topSKU" + getParameters(data),
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
};

const dashboardService = {
  getOrdersList,
  getGrossSaleList,
  getAvgPriceList,
  getSKURating,
  getAvgDeliveryTime
};

export default dashboardService;
