/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState, lazy } from "react";
import { Col, Container, Row } from "react-bootstrap";
import serial from "../../../shared/utils/serial";
import "./dashboard.css";
import { ResponsiveContainer } from "recharts";
import Loader from "../../../components/loader/Loader";
import TokenExpired from "../../../components/Alerts/TokenExpired";
import dashboardService from "../../../services/api/dashboardServie";
const ChartCard = lazy(() => import("./ChartCard"));
const ReportDetails = lazy(() => import("./ReportDetails"));
const DashboardHeader = lazy(() => import("./DashboardHeader"));

function Dashboard() {
  const [reportOpen, setReportOpen] = useState(false);
  const [reportData, setReportData] = useState({});
  const [filterData, setFilterData] = useState({});
  const [totalSale, setTotalSale] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAvgPrice, setTotalAvgPrice] = useState(0);
  const [totalAvgDelivery, setTotalAvgDelivery] = useState(0);
  const [error, setError] = useState();
  const [totalDataStatus, setTotalDataStatus] = useState(false);

  const getTotalSale = React.useCallback(() => {
    dashboardService
      .getGrossSaleList(filterData)
      .then((res) => {
        console.log("filterData ", filterData);
        let totalSale = 0;
        if (res.data && res.data.length > 0) {
          filterData.hasOwnProperty("storeId") && filterData["storeId"] !== "All"
            ? res.data[0].details.forEach((value) => {
                totalSale += value.total_amount;
              })
            : res.data.forEach((value) => {
                totalSale += value.gross_sales;
              });
          totalSale = parseFloat(totalSale).toFixed(
            parseFloat(totalSale) > parseInt(totalSale) ? 2 : 0
          );
        }
        setTotalSale(totalSale);
        setTotalDataStatus(true);
      })
      .catch((err) => {
        console.log("Gel Data Error ", err);
        setTotalDataStatus(true);
      });
  }, [filterData]);
  const getTotalOrders = React.useCallback(() => {
    dashboardService
      .getOrdersList(filterData)
      .then((res) => {
        let totalSale = 0;
        if (res.data && res.data.length > 0) {
          filterData.hasOwnProperty("storeId") && filterData["storeId"] !== "All"
            ? res.data[0].details.forEach((value, index) => {
                totalSale = index + 1;
              })
            : res.data.forEach((value) => {
                totalSale += value.orders;
              });
          totalSale = parseFloat(totalSale).toFixed(
            parseFloat(totalSale) > parseInt(totalSale) ? 2 : 0
          );
        }
        console.log("setTotalOrders :", totalSale);
        setTotalOrders(totalSale);
        setTotalDataStatus(true);
      })
      .catch((err) => {
        console.log("Gel Data Error ", err);
        setTotalDataStatus(true);
        if (err.status === 401) {
          setError(err);
        }
      });
  }, [filterData]);
  const getTotalAvgPrice = React.useCallback(() => {
    dashboardService
      .getAvgPriceList(filterData)
      .then((res) => {
        let AvgPrice = 0;
        if (res.data && res.data.length > 0) {
          res.data.forEach((value) => {
            AvgPrice += value.Avg_sale_price;
          });
          AvgPrice = parseFloat(AvgPrice).toFixed(
            parseFloat(AvgPrice) > parseInt(AvgPrice) ? 2 : 0
          );
        }
        setTotalAvgPrice(AvgPrice);
        setTotalDataStatus(true);
      })
      .catch((err) => {
        console.log("Gel Data Error ", err);
        setTotalDataStatus(true);
      });
  }, [filterData]);
  useEffect(() => {
    getTotalSale();
    getTotalOrders();
    getTotalAvgPrice();
    getTotalAvgDelivery();
  }, [getTotalSale, getTotalOrders, getTotalAvgPrice]);

  const getTotalAvgDelivery = () => {
    dashboardService.getAvgDeliveryTime().then((res) => {
      let AvgDelivery = 0;
      if (res.data && res.data.length > 0) {
        res.data.forEach((value) => {
          AvgDelivery += value.count;
        });
        AvgDelivery = parseFloat(AvgDelivery).toFixed(
          parseFloat(AvgDelivery) > parseInt(AvgDelivery) ? 2 : 0
        );
      }
      setTotalAvgDelivery(AvgDelivery);
    });
  };
  const reportClose = () => {
    console.log("reportClose call");
    setReportOpen(false);
  };
  const showDetails = (data, tabname, fixedPos = 2) => {
    console.log(data, tabname);
    setReportOpen(true);
    setReportData({ reportTitle: tabname, reportData: serial(data), fixedPos });
  };
  const getFilterData = (data) => {
    console.log("getFilterData call-2");
    setFilterData(data);
  };

  return (
    <>
      {error ? (
        <TokenExpired />
      ) : (
        <>
          <Container className="px-2" fluid>
            <DashboardHeader getFilterData={getFilterData} />
            {/* <Row className="mt-4">
              <Col sm={12}>
                <div className="cards cardlg center">
                  <img src={require("./kindpng_7246027.png")} className="mapimg" alt="" />
                </div>
              </Col>
            </Row> */}
            <Row className="mt-4">
              <Col sm={6}>
                <Row>
                  <Col sm={12}>
                    <div className="cards cardsm carddata">
                      {totalSale > 0 ? (
                        <>
                          <span>$ {totalSale}</span>
                          <span>Total Gross Sale</span>
                        </>
                      ) : (
                        <ResponsiveContainer
                          width="100%"
                          height="230px"
                          className="nodatacontainer">
                          {totalDataStatus ? <h4 className="noData">No Data...</h4> : <Loader />}
                        </ResponsiveContainer>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} className="mt-4">
                    <div className="cards cardsm carddata">
                      {totalOrders > 0 ? (
                        <>
                          <span>{totalOrders}</span>
                          <span>Total Orders</span>
                        </>
                      ) : (
                        <ResponsiveContainer
                          width="100%"
                          height="230px"
                          className="nodatacontainer">
                          {totalDataStatus ? <h4 className="noData">No Data...</h4> : <Loader />}
                        </ResponsiveContainer>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <ChartCard
                  cardTitle="Gross Sales"
                  getChartData={dashboardService.getGrossSaleList}
                  filterData={filterData}
                  footerValue={"$" + totalSale}
                  showDetails={(data) => showDetails(data, "Gross Sales")}
                  valueField={
                    filterData.hasOwnProperty("storeId") && filterData["storeId"] !== "All"
                      ? "total_amount"
                      : "gross_sales"
                  }
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={6}>
                <ChartCard
                  cardTitle="Orders"
                  getChartData={dashboardService.getOrdersList}
                  filterData={filterData}
                  footerValue={totalOrders}
                  showDetails={(data) => showDetails(data, "Orders", 0)}
                  valueField={
                    filterData.hasOwnProperty("storeId") && filterData["storeId"] !== "All"
                      ? "total_quantity"
                      : "orders"
                  }
                  sign={false}
                />
              </Col>
              <Col sm={6}>
                <ChartCard
                  cardTitle="Avg Price/Order"
                  filterData={filterData}
                  getChartData={dashboardService.getAvgPriceList}
                  footerValue={"$" + totalAvgPrice}
                  showDetails={(data) => showDetails(data, "Avg Price/Order")}
                  valueField={"Avg_sale_price"}
                />
              </Col>
            </Row>
            {/* <Row className="mt-4">
        <Col sm={6}>
          <ChartCard
            cardTitle="Avg Delivery Time"
            filterData={filterData}
            getChartData={dashboardService.getAvgDeliveryTime}
            footerTitle="Average Time"
            footerValue={totalAvgDelivery + " min"}
            sign={false}
            valueField="count"
            showDetails={(data) =>
              showDetails(data, "Average Delivery Time", 0)
            }
          />
        </Col>
        <Col sm={6}>
          <ChartCard
            cardTitle="SKU Rating"
            cardIcon={BsBag}
            // getChartData={dashboardService.getSKURating}
            footerTitle="10 SKU"
            footerValue="32"
            sign={false}
            showDetails={(data) => showDetails(data, "SKU Rating", 0)}
          />
        </Col>
      </Row> */}
            <ReportDetails
              reportOpen={reportOpen}
              reportClose={reportClose}
              reportData={reportData}></ReportDetails>
          </Container>
        </>
      )}
    </>
  );
}

export default Dashboard;
