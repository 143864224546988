/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from "react";
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Link
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import MerchantService from "../../services/api/merchantService";
import { useAppContext } from "../../hooks/AppContext";
import { useNavigate } from "react-router-dom";
export default function ForgotPassword() {
  const [buttonState, setButtonState] = useState();
  const { toaster } = useAppContext();
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is not valid")
  });
  const handlesubmits = (value) => {
    setButtonState("loading");
    MerchantService.forgotPassword(value)
      .then((res) => {
        console.log("res :>> ", res);
        setButtonState("success");
        if (res.result.success) {
          toaster({
            title: "Success",
            description: res.result.message
          });
          navigate("/login");
        } else {
          toaster({
            title: "Error",
            description: res.result.message,
            status: "error"
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setButtonState("fail");
      });
  };

  const initialValues = {
    email: "",
    role: "HOTSPOT"
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handlesubmits}>
      {({ handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}>
            <Stack
              spacing={4}
              w={"full"}
              maxW={"md"}
              bg={useColorModeValue("white", "gray.700")}
              rounded={"xl"}
              boxShadow={"lg"}
              p={6}
              my={12}>
              <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
                Forgot your password?
              </Heading>
              <Text
                fontSize={{ base: "sm", sm: "md" }}
                color={useColorModeValue("gray.800", "gray.400")}>
                You&apos;ll get an email with a reset link
              </Text>
              <FormControl id="email" isRequired>
                <Input
                  placeholder="your-email@example.com"
                  _placeholder={{ color: "gray.500" }}
                  type="email"
                  name="email"
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <Stack spacing={6}>
                <Button
                  bg={"orange.400"}
                  color={"white"}
                  type="submit"
                  _hover={{
                    bg: "orange.500"
                  }}
                  isLoading={buttonState === "loading"}>
                  Request Reset
                </Button>
                <Stack align="center" justify="center">
                  <Link
                    color={"blue.400"}
                    onClick={() => {
                      navigate("/login");
                    }}>
                    Goto Login Page
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Flex>
        </form>
      )}
    </Formik>
  );
}
