import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/hotspot.scss";
import { Layout, AdminDashboardLayout } from "./layout";
import {
  CategoryList,
  SkuList,
  SkuProductListNew,
  EnquiryListNew,
  UserManagment,
  OrderModule
  // Internal
} from "./pages";
import StoreEnquiryForm from "./pages/merchant/StoreInquiryForm";
import EmailConfirmation from "./pages/merchant/emailConfirmation";
import StoreLogin from "./pages/merchant/storeLogin";
import Location from "./pages/merchant/location";
import MarkHoliday from "./pages/merchant/markHoliday";
import { AppContextProvider } from "./hooks/AppContext";
import Profile from "./components/profile";
import EditProfile from "./components/profile/EditProfile";
import InviteStore from "./components/store/inviteStore";
import StoreList from "./components/store/StoreList";
import Dashboard from "./pages/admindashoard/dashboard/Dashboard";
import Loader from "./components/Spinner";
import Privacy from "./pages/merchant/Privacy";
import ForgotPassword from "./components/ForgetPassword";
import NotFound from "./pages/merchant/404";

const fallbackLoader = (
  <div className="centered">
    <Loader />
  </div>
);
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "production") {
  if (console) {
    console.log = function () {};
  }
}
function App() {
  return (
    <>
      <AppContextProvider>
        <Router>
          <Suspense fallback={fallbackLoader}>
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<StoreLogin />} />
                <Route path="inquiry" element={<StoreEnquiryForm />} />
                <Route path="login" element={<StoreLogin />} />
                <Route path="forgot" element={<ForgotPassword />} />
                <Route path="emailconfirmation" element={<EmailConfirmation />} />
                <Route path="location" element={<Location />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="markholiday" element={<MarkHoliday />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="/admin" exact element={<AdminDashboardLayout />}>
                <Route path="sku" element={<SkuList />} />
                <Route path="categories" element={<CategoryList />} />
                <Route path="enquirylist" element={<EnquiryListNew />} />
                <Route path="profile" element={<Profile />} />
                <Route path="profile/edit" element={<EditProfile />} />
                <Route path="productlist" element={<SkuProductListNew />} />
                <Route path="partner/store" element={<StoreList />} />
                <Route path="partner/invite" element={<InviteStore />} />
                <Route path="users" element={<UserManagment />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="order" element={<OrderModule />} />
                {/* <Route path="internal" element={<Internal />} /> */}
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </AppContextProvider>
    </>
  );
}
export default App;
