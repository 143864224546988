import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import image from "../../assets/logo/HotspotLogo.webp";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../hooks/AppContext";
import { Flex, Box, Stack, Button, Heading, useColorModeValue, Link } from "@chakra-ui/react";
import { InputText } from "../../components/form";
import { requestForToken } from "../../services/firebase";
const StoreLogin = () => {
  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is not valid"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
  });

  const initialValues = {
    email: "",
    password: ""
  };

  const [buttonState, setButtonState] = useState();

  const navigate = useNavigate();
  const { login, toaster } = useAppContext();
  const [token, setToken] = useState("");
  const navigateToAdmin = () => {
    navigate("/admin/dashboard");
  };
  useEffect(() => {
    requestForToken().then((res) => {
      setToken(res);
    });
  }, []);
  const handlesubmits = (value) => {
    setButtonState("loading");
    var password = value.password;
    // eslint-disable-next-line no-undef
    var md5 = require("md5");
    var hashedPassword = md5(password);
    const payload = {
      user_name: value.email,
      password: hashedPassword,
      firebase_token: token,
      platform: "WEB",
      role: "HOTSPOT"
    };
    login(payload, navigateToAdmin, loginFailed);
  };

  const loginFailed = () => {
    setButtonState("fail");
    toaster({
      title: "Login Failed",
      description: "Please Try Again",
      status: "error"
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handlesubmits}>
        {({ handleSubmit }) => (
          <>
            <Flex
              minH={"97.5vh"}
              align={"center"}
              justify={"center"}
              bg={useColorModeValue("gray.50", "gray.800")}>
              <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                <Stack align={"center"}>
                  <img src={image} className="img-responsive" alt="" style={{ width: 250 }} />
                  <Heading fontSize={"4xl"}>Sign in to your account</Heading>
                </Stack>
                <Box
                  rounded={"lg"}
                  bg={useColorModeValue("white", "gray.700")}
                  boxShadow={"lg"}
                  p={8}>
                  <form onSubmit={handleSubmit}>
                    <Stack spacing={4}>
                      <InputText label="Email address" name="email" type="email" />
                      <InputText label="Password" name="password" type="password" />

                      <Stack spacing={5}>
                        <Stack
                          direction={{ base: "column", sm: "row" }}
                          align={"start"}
                          justify={"center"}>
                          <Link
                            color={"blue.400"}
                            onClick={() => {
                              navigate("/forgot");
                            }}>
                            Forgot password?
                          </Link>
                        </Stack>
                        <Button
                          bg={"#ce4700"}
                          color={"white"}
                          _hover={{
                            bg: "#ce5700"
                          }}
                          type="submit"
                          isLoading={buttonState === "loading"}>
                          Sign in
                        </Button>
                      </Stack>
                    </Stack>
                  </form>
                </Box>
              </Stack>
            </Flex>
          </>
        )}
      </Formik>
    </>
  );
};

export default StoreLogin;
