import React, { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { requestForToken, onMessageListener } from "./firebase";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const toast = useToast();

  useEffect(() => {
    if (notification?.title) {
      // notify();
      console.log("notification", notification);
      toast({
        title: notification.title,
        description: notification.body,
        status: "info",
        duration: 9000,
        isClosable: true,
        position: "top-right"
      });
    }
  }, [notification]);

  requestForToken();

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log("failed: ", err));

  return <></>;
};

export default Notification;
