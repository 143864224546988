/* eslint-disable no-unused-vars */
import httpClient from "../../shared/lib/httpClient";
import AuthToken from "./authToken";
// services/api/message.js

function invite(id) {
  return httpClient({
    url: `v1/store/invitation/${id}`,
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
}

function create(payload) {
  return httpClient({
    url: "/register",
    method: "POST",
    data: {
      ...payload
    },
    headers: {
      Authorization: AuthToken
    }
  });
}

function login(payload) {
  return httpClient({
    url: "/login",
    method: "POST",
    data: {
      ...payload
    }
  });
}

function storeEnquiry(payload) {
  return httpClient({
    url: "o/v1/store/enquiry",
    method: "POST",
    data: {
      ...payload
    },
    headers: {
      Authorization: AuthToken
    }
  });
}
function storeEnquiryList() {
  return httpClient({
    url: "v1/store/enquiry",
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
}
function getAllStore() {
  return httpClient({
    url: "v1/store/all",
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
}
function getAllOrder(payload) {
  return httpClient({
    url: "v1/order",
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
}

function updateEnquiryList(id, value) {
  return httpClient({
    url: `v1/store/enquiry/${id}`,
    method: "PUT",
    data: {
      status: value
    },
    headers: {
      Authorization: AuthToken
    }
  });
}

function storeSignUp(payload) {
  return httpClient({
    url: "o/v1/store",
    method: "POST",
    data: {
      ...payload
    },
    headers: {
      Authorization: AuthToken
    }
  });
}

function getCancellationCharge(payload) {
  return httpClient({
    url: "v1/cancallationCharges",
    method: "GET",
    headers: {
      Authorization: AuthToken
    }
  });
}
function UpdateCancellationCharge(payload) {
  return httpClient({
    url: "v1/cancallationCharges",
    method: "POST",
    data: {
      ...payload
    },
    headers: {
      Authorization: AuthToken
    }
  });
}
const storeService = {
  invite,
  create,
  login,
  storeEnquiry,
  storeSignUp,
  storeEnquiryList,
  updateEnquiryList,
  getAllStore,
  getAllOrder,
  getCancellationCharge,
  UpdateCancellationCharge
  //, update, delete, etc. ...
};

export default storeService;
