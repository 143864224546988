const CONSTANTS = {
  api: {
    url: "https://api.hotspot.fun/rest/", //prod
    // url: "https://dev.hotspot.fun/rest/", // dev
    env: "prod"
  },
  firebaseConfig: {
    apiKey: "AIzaSyCNG2shhLoR-idortWFaY7mmS08ybg4TFQ",
    authDomain: "hot-spot-f0328.firebaseapp.com",
    projectId: "hot-spot-f0328",
    storageBucket: "hot-spot-f0328.appspot.com",
    messagingSenderId: "46642417422",
    appId: "1:46642417422:web:3a464bdd96afe8a391515f",
    measurementId: "G-3CX2JDFJXM"
  }
};
export default CONSTANTS;
