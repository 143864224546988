import { lazy } from "react";
// admin dashboard pages
const CategoryList = lazy(() => import("./admindashoard/category/categoryList"));
const SkuList = lazy(() => import("./admindashoard/sku/skuList"));
const StoreList = lazy(() => import("./admindashoard/store/store"));
const SkuProductListNew = lazy(() => import("./admindashoard/skuProduct/SkuProductListNew"));
const PartnerStore = lazy(() => import("./merchant/PartnerStore"));
const EnquiryListNew = lazy(() => import("./merchant/EnquiryListNew"));
const UserManagment = lazy(() => import("./admindashoard/userManagment/UserManagment"));
const OrderModule = lazy(() => import("./admindashoard/order"));
const Internal = lazy(() => import("./admindashoard/internal/"));

// merchant pages
export {
  CategoryList,
  SkuList,
  StoreList,
  UserManagment,
  SkuProductListNew,
  PartnerStore,
  EnquiryListNew,
  OrderModule,
  Internal
};
