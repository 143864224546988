import React, { useState } from "react";
import {
  Stack,
  FormControl,
  Input,
  Button,
  Flex,
  FormHelperText,
  FormLabel
} from "@chakra-ui/react";
import Image2 from "./blue.jpg";
import { Formik } from "formik";
import * as Yup from "yup";
import MerchantService from "../../services/api/merchantService";
import StoreService from "../../services/api/storeService";
import { useAppContext } from "../../hooks/AppContext";
import { Container, Row, Col, Card } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Message from "../../shared/validationMessage.json";

export default function InviteStore() {
  const { toaster } = useAppContext();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(40)
      .required("First Name is a required field"),
    last_name: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(40)
      .required("Last Name is a required field"),
    email: Yup.string().email().required("Email Address is a required field"),
    store_name: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter Store name")
      .max(40)
      .required("Store Name is required"),
    phone: Yup.string()
      .required("Phone Number is a required field")
      .matches(phoneRegExp, "Phone number is not valid")
  });
  const initial = {
    email: "",
    store_name: "",
    first_name: "",
    last_name: "",
    phone: ""
  };

  async function submitStoreInfo(storeInfo, resetForm) {
    console.log("storeInfo :>> ", storeInfo);

    MerchantService.storeEnquiry(storeInfo)
      .then(
        (data) => {
          const id = data.data.id;
          if (id === undefined) {
            toaster({ title: data.result.message });
          } else {
            StoreService.updateEnquiryList(id, 1)
              .then(() => {
                StoreService.invite(id).then(() => {
                  toaster({ title: "Invite Send Successfully" });
                });
                resetForm();
              })
              .catch(() => {
                toaster({ title: "Error", description: "Something went wrong!", status: "error" });
              });
          }
        },
        (fail) => {
          console.error(fail);
          toaster(Message.error);
        }
      )
      .catch((err) => {
        console.log("err :>> ", err);
      })
      .finally(() => {
        setButtonState("done");
      });
  }
  const [buttonState, setButtonState] = useState();
  return (
    <>
      <Container fluid>
        <Card>
          <Card.Header className="text-center">Invite New Store</Card.Header>
          <Card.Body>
            <Row>
              <Col className="d-flex justify-content-center">
                <LazyLoadImage
                  src={Image2}
                  width={500}
                  height={320}
                  alt="Image Alt"
                  className="invite-store-image"
                />
              </Col>
              <Col>
                <Formik
                  enableReinitialize
                  initialValues={initial}
                  onSubmit={(values, { resetForm }) => {
                    setButtonState("loading");
                    submitStoreInfo(values, resetForm);
                  }}
                  validationSchema={schema}>
                  {({ handleChange, handleSubmit, handleBlur, values, errors, resetForm }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <Flex align={"center"} justify={"center"}>
                          <Stack
                            spacing={4}
                            w={"full"}
                            maxW={800}
                            rounded={"xl"}
                            boxShadow={"lg"}
                            p={4}>
                            {/* <Box align={"center"} justify={"center"} mb="5">
                              <Image src={Image1} alt="Dan Abramov" boxSize="200px" />
                            </Box> */}
                            <FormControl id="first_name" isRequired isInvalid={errors.first_name}>
                              <FormLabel>First Name</FormLabel>
                              <Input
                                placeholder="Enter the First Name"
                                _placeholder={{ color: "gray.500" }}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.first_name}
                              />
                              {errors.first_name && (
                                <FormHelperText color={"#F56565"}>
                                  {errors.first_name}
                                </FormHelperText>
                              )}
                            </FormControl>

                            <FormControl id="last_name" isRequired isInvalid={errors.last_name}>
                              <FormLabel>Last Name</FormLabel>
                              <Input
                                placeholder="Enter the Last Name"
                                _placeholder={{ color: "gray.500" }}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.last_name}
                              />
                              {errors.last_name && (
                                <FormHelperText color={"#F56565"}>
                                  {errors.last_name}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <FormControl id="phone" isRequired isInvalid={errors.phone}>
                              <FormLabel>Phone</FormLabel>
                              <Input
                                placeholder="Enter the Phone"
                                _placeholder={{ color: "gray.500" }}
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              {errors.phone && (
                                <FormHelperText color={"#F56565"}>{errors.phone}</FormHelperText>
                              )}
                            </FormControl>
                            <FormControl id="email" isRequired isInvalid={errors.email}>
                              <FormLabel>Email Address</FormLabel>
                              <Input
                                placeholder=" Enter your Email Address"
                                _placeholder={{ color: "gray.500" }}
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              {errors.email && (
                                <FormHelperText color={"#F56565"}>{errors.email}</FormHelperText>
                              )}
                            </FormControl>
                            <FormControl id="store_name" isRequired isInvalid={errors.store_name}>
                              <FormLabel>Store Name</FormLabel>
                              <Input
                                placeholder=" Enter the Store Name"
                                _placeholder={{ color: "gray.500" }}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.store_name}
                                // isValid={touched.store_name && !errors.store_name}
                              />
                              {errors.store_name && (
                                <FormHelperText color={"#F56565"}>
                                  {errors.store_name}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <Stack spacing={6} direction={["column", "row"]}>
                              <Button
                                colorScheme="orange"
                                variant="outline"
                                w="full"
                                onClick={resetForm}
                                type="reset">
                                Reset
                              </Button>
                              <Button
                                colorScheme="orange"
                                color={"white"}
                                w="full"
                                type="submit"
                                isLoading={buttonState === "loading"}>
                                Submit
                              </Button>
                            </Stack>
                          </Stack>
                        </Flex>
                      </form>
                    </>
                  )}
                </Formik>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
